<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/cycle' }">巡检计划</el-breadcrumb-item>
          <el-breadcrumb-item>新增计划</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <!-- 基本信息 -->
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="巡检代号" prop="name">
                <el-input v-model="form.name" placeholder="请输入巡检代号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="起止日期" prop="date">
                <el-date-picker
                  style="width: 335px"
                  v-model="form.date"
                  type="daterange"
                  range-separator="至"
                  value-format="timestamp"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          
          <!-- 巡视点信息配置 -->
          <div class="config-title">
            <div class="form-title">
              巡视点信息<span style="color: red"> *</span>
            </div>
            <div>
              <el-button @click="addPatrol" type="primary" size="small">添加巡视点</el-button>
            </div>
          </div>
          <el-table :data="selectPatrolData" style="width: 100%">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="departmentName" label="所属部门"></el-table-column>
            <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <div>
                  <span @click="dltPatrol(scope.row)" style="color:red;cursor:pointer;">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- 巡检人员配置 -->
          <div class="config-title">
            <div class="form-title">
              巡检人员配置<span style="color: red"> *</span>
            </div>
          </div>
          <el-row :gutter="20">
            <el-col :span="24" class="lubri-col">
              <div class="lubri-tabs">巡检周期配置</div>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="自定义" name="custom">
                  <!-- 自定义内容 -->
                  <!-- <div style="text-align:right;">
                    <el-button @click="addCustomConfig" type="primary" size="small">增加自定义配置</el-button>
                  </div> -->
                  <div v-for="(item, key) in form.customConfig" :key="key" class="config-box" style="padding-top:30px;">
                    <!-- <div class="title">
                      <div style="display: flex;">
                        <span class="iconfont icon-peizhi" style="font-size: 20px;margin-right: 5px;"></span>
                        <span> 自定义配置 {{key}} </span>
                      </div>
                      <div><i @click="customConfigDate(key)" v-if="key != 1" class="el-icon-close"></i></div>
                    </div> -->
                    <el-form-item label="巡检员">
                      <div @click="addCustomStaff(item, key)" class="staffBox">
                        <el-tag v-for="staff in item.staffList" :key="staff.id" style="margin-right: 4px;">
                          {{ staff.name }}
                        </el-tag>
                      </div>
                    </el-form-item>
                    <el-row :gutter="40">
                      <el-col :span="8">
                        <el-form-item label="首次工作日期" label-width="110px">
                          <el-date-picker
                            v-model="item.firstWorkDate"
                            value-format="timestamp"
                            type="date"
                            placeholder="选择日期">
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-row :gutter="20" class="cycles" style="margin-left:30px;margin-right:-40px;">
                          <el-col :span="20">
                            <el-form-item label="工作天数">
                              <el-input v-model="item.work" type="number"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="1" style="padding-left:0;">
                            <el-form-item label="天" label-width="0px"></el-form-item>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="8">
                        <el-row :gutter="20" class="cycles" style="margin-left:-20px;margin-right:10px;">
                          <el-col :span="20">
                            <el-form-item label="间隔天数">
                              <el-input v-model="item.rest" type="number"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="1" style="padding-left:0;">
                            <el-form-item label="天" label-width="0px"></el-form-item>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <div class="setWeek">
                        <div class="box">
                          <div class="week-title">
                            <el-form-item label="巡检工作时间段" label-width="115px">
                              <i @click="addLubriDate(item)" class="el-icon-plus"></i>
                            </el-form-item>
                          </div>
                          <div v-for="(cDate, cIndex) in item.frequencyTimeInfos" :key="cIndex" class="date">
                            <span class="date-num">{{cIndex+1}}.</span>
                            开始时间：
                            <span style="position: relative;">
                              <lay-time :date="cDate.date[0]" :index="[key, cIndex, 0]" @change="laytimeLubriEnd"></lay-time>
                            </span>
                            
                            结束时间：
                            <span style="position: relative;">
                              <lay-time :date="cDate.date[1]" :index="[key, cIndex, 1]" @change="laytimeLubriEnd"></lay-time>
                            </span>
                            <i @click="deleteLubriDate(item, cIndex)" v-if="cIndex != 0" class="el-icon-close" style="margin-top: 20px;"></i>
                          </div>
                        </div>
                      </div>
                    </el-row>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="周配置" name="weekConfig">
                  <!-- 周配置内容 -->
                  <div style="text-align:right;">
                    <el-button @click="addConfig" type="primary" size="small">增加周配置</el-button>
                  </div>
                  <div v-for="(item, key) in form.config" :key="key" class="config-box">
                    <div class="title">
                      <div style="display: flex;">
                        <span class="iconfont icon-peizhi" style="font-size: 20px;margin-right: 5px;"></span>
                        <span> 周配置 {{key}} </span>
                      </div>
                      <div><i @click="configDate(key)" v-if="key != 1" class="el-icon-close"></i></div>
                    </div>
                    <el-form-item label="巡检员">
                      <div @click="addStaff(item, key)" class="staffBox">
                        <el-tag v-for="staff in item.staffList" :key="staff.id" style="margin-right: 4px;">
                          {{ staff.name }}
                        </el-tag>
                      </div>
                    </el-form-item>
                    <el-form-item label="工作时间">
                      <el-checkbox-group v-model="item.weekList" @change="checkboxChange(item)" >
                        <el-checkbox label="星期一"></el-checkbox>
                        <el-checkbox label="星期二"></el-checkbox>
                        <el-checkbox label="星期三"></el-checkbox>
                        <el-checkbox label="星期四"></el-checkbox>
                        <el-checkbox label="星期五"></el-checkbox>
                        <el-checkbox label="星期六"></el-checkbox>
                        <el-checkbox label="星期日"></el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>  
                    <div class="setWeek">
                      <div class="box" v-for="(value, vKey) in item.weekDateList" :key="vKey">
                        <div class="week-title">
                          {{ vKey }}
                          <i @click="addWeekDate(value)" class="el-icon-plus"></i>
                        </div>
                        <div v-for="(date, dIndex) in value.dates" :key="dIndex" class="date">
                          <span class="date-num">{{dIndex+1}}.</span>
                          开始时间：
                          <span style="position: relative;">
                            <lay-time :date="date.date[0]" :index="[key, vKey, dIndex, 0]" @change="laytimeEnd"></lay-time>
                          </span>
                          结束时间：
                          <span style="position: relative;">
                            <lay-time :date="date.date[1]" :index="[key, vKey, dIndex, 1]" @change="laytimeEnd"></lay-time>
                          </span>
                          <i @click="deleteDate(value, dIndex)" v-if="dIndex != 0" class="el-icon-close" style="margin-top: 20px;"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/cycle')">取消</el-button>
            <el-button type="primary" size="small" @click="onSubmit">提交</el-button>
          </div>
        </el-form>
      </div>
      <!-- 巡视点侧滑 -->
      <el-drawer
        title="添加巡视点"
        :with-header="false"
        :visible.sync="patrolDrawer"
        direction="rtl"
        size="800px">
        <div class="drawer-title">添加巡视点</div>
        <div class="drawer-content table-tree">
          <div class="tree">
            <div class="tree-title">所属部门</div>
            <el-tree
              :data="departmentTreeList"
              node-key="id"
              @node-click="departmentClick"
              :props="patrolProps"
              :default-checked-keys="[1]"
              highlight-current
              :current-node-key="departmentId"
              ref="patrolTree">
              <span class="custom-tree-node" slot-scope="{ node }">
                <span style="margin-right: 40px">{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
          <div class="table">
            <el-table :data="tableDataCp" style="width: 500px" height="700" v-loading="patrolLoading" element-loading-spinner="el-icon-loading">
              <el-table-column label="勾选" width="70">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @change="checkeChange(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="patrolCancel">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="patrolDrawerOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
      <!-- 人员侧滑 -->
      <el-drawer
        title=""
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
        size="900px">
        <div class="drawer-title">选择巡检员</div>
        <div class="drawer-content" v-if="drawer">
          <div class="table-tree">
            <div class="tree">
              <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
              <el-tree
                :data="treeData"
                @node-click="treeRowClick"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                ref="tree">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                </span>
              </el-tree>
            </div>
            
            <div class="table">
              <el-table :data="tableData" style="width: 100%" height="700" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
                <el-table-column width="40">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.check" :checked="staffListID.includes(scope.row.id)" @change="checked(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sex" label="性别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-else>女</span>
                  </template>
                </el-table-column>
                <el-table-column prop="departmentName" label="部门"></el-table-column>
                <el-table-column prop="name" label="岗位">
                  <template slot-scope="scope">
                    <div
                      v-for="item in scope.row.positionInfoList"
                      :key="item.positionId"
                    >{{item.positionName}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button type="primary" size="small" @click="drawer=false">取消</el-button>
          <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import layTime from '@/components/layTime'
export default {
  components: {
    layTime
  },
  data() {
    return {
      loading: false,
      patrolLoading:false,
      tableLoading: false,
      form: {
        patrolIds: [],
        customConfig: {
          1:{
            firstWorkDate: null,
            work: null,
            rest: null,
            staffList: [],
            frequencyTimeInfos: []
          }
        },
        config: {}
      },
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        date:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
       // tabs
      activeName:'custom',
      props: {
        value: 'id',
        label: 'name',
        children: 'childTree'
      },
      patrolDrawer: false,
      departmentId: 0,
      departmentTreeList: [],
      tableDataCp:[],
      tableSelectList: [],
      selectPatrolData: [],
      patrolProps: {
        children: 'childTree',
        label: 'name'
      },

      options: [],
      bumen: [],
      value: [],
      patrolList: [],
      staffList: [],
      index: 1,
      customIndex: 1,
      departmentTree: [],
      drawer: false,
      treeData: [],
      tableData: [],
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      checkedStaffList: [],
      staffListID: [],
      customConfigKey: '',
      configKey: '',
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
    }
  },
  methods: {
    // 添加巡视点
    addPatrol(){
      this.patrolDrawer = true
      this.tableDataCp.forEach((v)=>{ 
        if (this.selectPatrolData.find(item => item.id == v.id)) {
          v.checked = true
        }else {
          v.checked = false
        }
      })
    },
    // 点击部门获取对应巡视点
    departmentClick(data,node){
      if(this.tableSelectList.length==0){
        this.tableDataCp = []
        this.tableSelectList = []
        this.selectPatrolData = []
        this.getPatrolList(data.id, data.name)
      }else {
        if(this.tableSelectList[0].departmentId==data.id) {
          this.$refs.patrolTree.setCurrentKey(data.id)
        }else{
          node.isCurrent = false
          this.$refs.patrolTree.setCurrentKey(this.tableSelectList[0].departmentId)
          this.$message.error("不能跨部门选择巡视点信息！");
        }
      }
    },
    getPatrolList(id, name){
      this.patrolLoading = true
      this.$ajax.post("patrolList", {
        departmentId: id
      }).then((res) => {
        res.data.forEach(v => {
          v.checked = false
          v.departmentId = id
          v.departmentName = name
          this.tableDataCp.push(v)
        })
        this.patrolLoading = false
      }).catch(err=>{
        this.patrolLoading = false
      })
    },
    // 勾选设备
    checkeChange(row) {
      if (row.checked) {
        // 勾选
        this.tableSelectList.push(row)
      } else {
        this.tableSelectList.forEach((item, index) => {
          if (item.id == row.id) {
            this.tableSelectList.splice(index, 1)
          }
        })
      }
    },
    patrolCancel() {
      this.patrolDrawer = false
    },
    // 选择巡视点点击确定
    patrolDrawerOK(){
      this.patrolDrawer = false
      this.selectPatrolData = []
      this.tableSelectList.forEach(v=>{
        this.selectPatrolData.push(v)
      })
    },
    // 删除巡视点信息
    dltPatrol(row){
      this.selectPatrolData.forEach((item, index) => {
        if (item.id == row.id) {
          this.selectPatrolData.splice(index, 1)
        }
      })
      this.tableSelectList.forEach((v,k) => {
        if (v.id == row.id) {
          this.tableSelectList.splice(k, 1)
        }
      })
    },
    customConfigDate(index) {
      this.$delete(this.form.customConfig, index)
    },
    configDate(index) {
      this.$delete(this.form.config, index)
    },
    laytimeEnd(index, date) {
      this.form.config[index[0]].weekDateList[index[1]].dates[index[2]].date[index[3]] = date
      console.log(index)
    },
    drawerOK() {
      this.drawer = false
      if(this.activeName == 'custom') {
        this.form.customConfig[this.customConfigKey].staffList = [].concat(this.checkedStaffList)
        this.form.customConfig[this.customConfigKey].staffListID = [].concat(this.staffListID)
      }else {
        this.form.config[this.configKey].staffList = [].concat(this.checkedStaffList)
        this.form.config[this.configKey].staffListID = [].concat(this.staffListID)
      }
    },
    checked(row) {
      // if (row.check) {
      if (!this.staffListID.includes(row.id)) {
        this.checkedStaffList.push(row)
        this.staffListID.push(row.id)
      } else {
        this.checkedStaffList.forEach((item, index) => {
          if (item.id == row.id) {
            this.checkedStaffList.splice(index, 1)

            this.$delete(this.staffListID, index)
            console.log(this.staffListID)
          }
        })
      }
    },
    loadTableData(id) {
      this.tableLoading = true
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        res.data.forEach(item => {
          let inIs = this.staffListID.find(function(obj) {
            return obj == item.id
          })
          if (!inIs) {
            item.check = false
          }else {
            item.check = true
          }
        })
        this.tableData = res.data
        this.tableLoading = false
      }).catch(err=>{
        this.tableLoading = false
      })
    },
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    loadTreeData() {
      this.$ajax.post('departmentResponsibleChild').then(res => {
        this.treeData = res.data
      })
    },
    handleClick(tab, event){},
    addCustomStaff(item, key) {
      this.loadTableData(this.treeData[0].id)
      this.customConfigKey = key
      this.staffListID = []
      item.staffList.forEach(item => {
        this.staffListID.push(item.id)
      })
      this.checkedStaffList = [].concat(item.staffList)
      
      this.drawer = true
    },
    addStaff(item, key) {
      this.loadTableData(this.treeData[0].id)
      this.configKey = key
      this.staffListID = []
      item.staffList.forEach(item => {
        this.staffListID.push(item.id)
      })
      this.checkedStaffList = [].concat(item.staffList)
      
      this.drawer = true
    },
    deleteDate(value, dIndex) {
      this.$delete(value.dates, dIndex)
    },
    addWeekDate(item) {
      item.dates.push({date: ['00:00:00', '23:00:00']})
    },
    addCustomConfig() {
      this.$set(this.form.customConfig, this.customIndex, {
        firstWorkDate: null,
        work: null,
        rest: null,
        staffList: [],
        frequencyTimeInfos: []
      });
      this.customIndex++;
    },
    addConfig() {
      this.$set(this.form.config, this.index, {
        weekList: [],
        weekDateList: {},
        staffList: []
      });
      this.index++;
    },
    checkboxChange(item) {
      // 补缺
      item.weekList.forEach(week => {
        if (item.weekDateList[week] == undefined) {
          this.$set(item.weekDateList, week, {
            dates: [{date: ['00:00:00', '23:00:00']}]
          })
        }
      })
      // 去无
      for (let d in item.weekDateList) {
        if (!item.weekList.includes(d)) {
          this.$delete(item.weekDateList, d)
        }
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let unsub = true
          let _this = this
          let form = {
            taskType: 0,
            taskCode: this.form.name,
            startDate: this.form.date[0],
            endDate: this.form.date[1],
            patrolIds: [],
            departmentId: 0,
            frequencyType: this.activeName=='weekConfig'?0:1,
            staffGroups: []
          }

          this.selectPatrolData.forEach((v)=>{
            form.patrolIds.push(v.id)
            form.departmentId = v.departmentId
          })

          if (form.patrolIds.length == 0) {
            this.$message.error("巡视点信息不能为空！");
            return
          }

          if(this.activeName=='custom') {
            if (Object.keys(this.form.customConfig).length == 0) {
              this.$message.error("请增加一个自定义配置！");
              return
            }

            for (let item in this.form.customConfig) {
              if(this.form.customConfig[item].staffListID==undefined){
                this.$message.error("巡检人员不能为空！");
                return
              }
              if(!this.form.customConfig[item].firstWorkDate){
                this.$message.error("首次工作日期不能为空！");
                return
              }
              if(!this.form.customConfig[item].work){
                this.$message.error("工作天数不能为空！");
                return
              }
              if(!this.form.customConfig[item].rest){
                this.$message.error("间隔天数不能为空！");
                return
              }
              if(this.form.customConfig[item].frequencyTimeInfos.length == 0){
                this.$message.error("巡检工作时间段不能为空！");
                return
              }
              // 自定义配置格式化时间段
              let frequencyTimeArr = []
              this.form.customConfig[item].frequencyTimeInfos.forEach(a => {
                frequencyTimeArr.push({
                  startTime: _this.dateSecond(a.date[0]),
                  endTime: _this.dateSecond(a.date[1])
                })
                if (_this.dateSecond(a.date[0]) > _this.dateSecond(a.date[1])) {
                  unsub = false
                }
              })

              form.staffGroups.push({
                staffIds: this.form.customConfig[item].staffListID,
                cycleFrequencyDate: {
                  firstDate: parseInt(this.form.customConfig[item].firstWorkDate),
                  workDay: parseInt(this.form.customConfig[item].work),
                  restDay: parseInt(this.form.customConfig[item].rest),
                  frequencyTimeInfos: frequencyTimeArr
                }
              })
            }
          }else {
            if (Object.keys(this.form.config).length == 0) {
              this.$message.error("请增加一个周配置！");
              return
            }

            for (let item in this.form.config) {
              if(this.form.config[item].staffListID==undefined){
                this.$message.error("巡检人员不能为空！");
                return
              }
              if(this.form.config[item].weekList.length == 0) {
                this.$message.error("工作时间不能为空！");
                return
              }
              form.staffGroups.push({
                staffIds: this.form.config[item].staffListID,
                frequencyDates: getfrequencyDate(this.form.config[item].weekDateList)
              })
            }
          }

          function getfrequencyDate (weekDateList) {
            var data = []
            for (let week in weekDateList) {
              let weekIndex = ''
              if (week == '星期一') {
                weekIndex = 1
              } else if (week == '星期二') {
                weekIndex = 2
              } else if (week == '星期三') {
                weekIndex = 3
              } else if (week == '星期四') {
                weekIndex = 4
              } else if (week == '星期五') {
                weekIndex = 5
              } else if (week == '星期六') {
                weekIndex = 6
              } else if (week == '星期日') {
                weekIndex = 0
              }
              let arr = []
              weekDateList[week].dates.forEach(a => {
                arr.push({
                  startTime: _this.dateSecond(a.date[0]),
                  endTime: _this.dateSecond(a.date[1])
                })
                if (_this.dateSecond(a.date[0]) > _this.dateSecond(a.date[1])) {
                  unsub = false
                }
              })
              data.push({
                frequencyTimeInfos: arr,
                weekIndex: weekIndex
              })
            }
            return data
          }

          if (unsub) {
            this.loading = true
            this.$ajax.post("ruleInspection", form).then((res) => {
              this.loading = false
              this.$message.success("成功");
              this.$router.push("/cycle");
            }).catch(err=>{
              this.loading = false
            })
          } else {
            this.$message.error("开始时间 不能大于 结束时间！");
          }
        }
      })
    },
    // 自定义增加时间段
    addLubriDate(item){
      item.frequencyTimeInfos.push({date: ['00:00:00', '23:00:00']})
    },
    // 自定义删除时间段
    deleteLubriDate(item, cIndex){
      this.$delete(item.frequencyTimeInfos, cIndex)
    },
    // 自定义选择时间段
    laytimeLubriEnd(index, date){
      this.form.customConfig[index[0]].frequencyTimeInfos[index[1]].date[index[2]] = date
    },
    dateSecond(str) {
      let strArr = str.split(':')
      let second =  (strArr[0] * 3600) + (strArr[1] * 60) + Number(strArr[2])
      return second
    },
    loadPatrolList() {
      this.$ajax.post("patrolList", {
        facilityId: ''
      }).then((res) => {
        this.patrolList = res.data;
      });
    },
    load_bumen() {
      this.$ajax.get("departmentTree").then((res) => {
        this.bumen = this.getTreeData(res.data);
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    loadStaffList() {
      this.$ajax.get("staffDueryAll").then((res) => {
        this.staffList = res.data;
      });
    },
    loadDepartmentTree() {
      this.$ajax.get("departmentTree").then((res) => {
        this.departmentTree = res.data;
      })
    },
    departmentList(){
      this.$ajax.get("departmentResponsibleSecondLevel").then((res) => {
        this.departmentTreeList = res.data;
        this.departmentClick(res.data[0])
        this.departmentId = res.data[0].id
      })
    }
  },
  mounted() {
    this.departmentList()
    this.loadPatrolList()
    this.loadStaffList()
    this.loadTreeData()
    // this.load_bumen()
    // this.loadDepartmentTree()
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-title {
  font-weight: bold;
}
.config-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  margin-right: 140px;
  padding: 4px;
  min-height: 40px;
  transition: all .3s ease;
}
.staffBox:hover {
  border: 1px solid #409EFF;
}
.el-icon-close {
  color: #1494fb;
  margin-right: 10px;
  cursor: pointer;
  float: right;
  font-size: 20px;
}
.config-box {
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border: 1px solid #ebeef5;
  border-radius: 10px;
  margin: 20px 0;
  .title {
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
  }
}
.setWeek {
  border-top: 0;
  .date {
    padding-bottom: 20px;
    padding-left: 80px;
    font-size: 14px;
  }
  .date-num {
    font-size: 12px;
    margin: 0 10px;
  }
  .week-title {
    font-size: 14px;
    padding-bottom: 10px;
    margin-left: 35px;
    i {
      font-weight: bold;
      color: #1494fb;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .title {
    font-size: 14px;
    padding-left: 10px;
    line-height: 30px;
    background-color: #f6f7fb;
  }
  .box {
    padding: 10px;
    margin-bottom: 6px;
  }
  .box:last-child {
    margin: 0;
  }
}
.checkbox {
  margin-bottom: 20px;
  padding-left: 40px;
}
.lubri-col /deep/.el-tabs__nav{
  left: 140px;
}
.lubri-col /deep/.el-tabs__nav-wrap::after {
  position: static !important;
}
.lubri-tabs {
  font-size:14px;
  font-weight: bold;
  color:#606266;
  line-height: 40px;
  margin-left: 30px;
  position: absolute;
}
.cycles /deep/.el-input__inner {
  text-align: center;
} 
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
</style>